import { PDFViewer, StyleSheet, Document, View, Text, Page } from "@react-pdf/renderer";
import { CommonData, Detail, getDetails, SessionState } from "../utils";
import { DetailsType } from "./DetailsGrid";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		fontSize: "10px",
		paddingTop: "45px",
		paddingBottom: "45px",
	},
	titleContainer: {
		flexDirection: "row",
	},
	title: {
		fontSize: "14px",
		padding: "2px",
		width: "50%",
	},
	date: {
		padding: "2px",
		width: "50%",
		textAlign: "right",
		verticalAlign: "sub",
	},
	table: {
		marginTop: "8px",
	},
	headerRow: {
		flexDirection: "row",
		backgroundColor: "#666",
		color: "#fff",
	},
	row: {
		flexDirection: "row",
		borderBottom: "1px dotted black",
	},
	passedRow: {
		flexDirection: "row",
		borderBottom: "1px dotted black",
		color: "#aaa",
	},
	dateRow: {
		width: "20%",
		padding: "4px",
	},
	assigneeRow: {
		width: "20%",
		padding: "4px",
	},
	descriptionRow: {
		width: "60%",
		padding: "4px",
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1,
	},
	detailsHeader: {
		paddingTop: "16px",
		fontSize: "12px",
	},
});

const PDFDocument = ({
	commonData,
	details,
}: {
	commonData: CommonData;
	caseData: { [id: string]: string };
	details: { notes: Detail[]; tasks: Detail[]; deadlines: Detail[] };
}) => {
	const { caseNumber } = commonData;
	const { notes, tasks, deadlines } = details;
	return (
		<Document>
			<Page size="LETTER" style={styles.page}>
				<View style={styles.section}>
					<View style={styles.titleContainer}>
						<Text style={styles.title}>Details for Case Number {caseNumber}</Text>
					</View>
					<View style={styles.detailsHeader}>
						<Text>Notes</Text>
					</View>
					<View style={styles.table}>
						<View style={styles.headerRow}>
							<Text style={styles.dateRow}>Date</Text>
							<Text style={styles.assigneeRow}>Assignee</Text>
							<Text style={styles.descriptionRow}>Description</Text>
						</View>
						{notes.map(({ date, assignee, description }) => (
							<View style={styles.row}>
								<Text style={styles.dateRow}>{dayjs(date).format("MM/DD/YYYY hh:mm A")}</Text>
								<Text style={styles.assigneeRow}>{assignee}</Text>
								<Text style={styles.descriptionRow}>{description}</Text>
							</View>
						))}
					</View>
					<View style={styles.detailsHeader}>
						<Text>Tasks</Text>
					</View>
					<View style={styles.table}>
						<View style={styles.headerRow}>
							<Text style={styles.dateRow}>Date</Text>
							<Text style={styles.assigneeRow}>Assignee</Text>
							<Text style={styles.descriptionRow}>Description</Text>
						</View>
						{tasks.map(({ date, assignee, description, isComplete }) => (
							<View style={styles.row}>
								<Text style={{ padding: "4px", left: "-10px", position: "absolute" }}>{isComplete ? "×" : ""}</Text>
								<Text style={styles.dateRow}>{dayjs(date).format("MM/DD/YYYY hh:mm A")}</Text>
								<Text style={styles.assigneeRow}>{assignee}</Text>
								<Text style={styles.descriptionRow}>{description}</Text>
							</View>
						))}
					</View>
					<View style={styles.detailsHeader}>
						<Text>Deadlines</Text>
					</View>
					<View style={styles.table}>
						<View style={styles.headerRow}>
							<Text style={styles.dateRow}>Date</Text>
							<Text style={styles.assigneeRow}>Assignee</Text>
							<Text style={styles.descriptionRow}>Description</Text>
						</View>
						{deadlines.map(({ date, assignee, description, isComplete }) => {
							const isPassed = dayjs(date).diff(dayjs(), "days") < -1;
							return (
								<View style={isPassed ? styles.passedRow : styles.row}>
									<Text style={{ padding: "4px", left: "-10px", position: "absolute" }}>{isComplete ? "×" : ""}</Text>
									<Text style={styles.dateRow}>{dayjs(date).format("MM/DD/YYYY hh:mm A")}</Text>
									<Text style={styles.assigneeRow}>{assignee}</Text>
									<Text style={styles.descriptionRow}>{description}</Text>
								</View>
							);
						})}
					</View>
				</View>
			</Page>
		</Document>
	);
};

export const DetailsPDF = ({
	commonData,
	caseData,
	id,
	sessionState,
	setSessionState,
}: {
	commonData: CommonData;
	caseData: { [id: string]: string };
	id: string;
	sessionState: SessionState;
	setSessionState: (arg0: SessionState) => void;
}) => {
	const notesQuery = useQuery({
		queryKey: ["details", DetailsType.Note, id],
		queryFn: () =>
			getDetails(
				{
					archived: false,
					caseId: id,
					type: "note",
				},
				sessionState,
				setSessionState
			),
		enabled: false,
	});
	const tasksQuery = useQuery({
		queryKey: ["details", DetailsType.Task, id],
		queryFn: () =>
			getDetails(
				{
					archived: false,
					caseId: id,
					type: "task",
				},
				sessionState,
				setSessionState
			),
		enabled: false,
	});
	const deadlinesQuery = useQuery({
		queryKey: ["details", DetailsType.Deadline, id],
		queryFn: () =>
			getDetails(
				{
					archived: false,
					caseId: id,
					type: "deadline",
				},
				sessionState,
				setSessionState
			),
		enabled: false,
	});
	return (
		<PDFViewer
			style={{
				width: "100%",
				height: "100%",
			}}
		>
			<PDFDocument
				commonData={commonData}
				caseData={caseData}
				details={{
					notes: (notesQuery.data || []).toSorted((a: { date: string }, b: { date: string }) => -dayjs(a.date).diff(dayjs(b.date))),
					tasks: (tasksQuery.data || []).toSorted((a: { date: string }, b: { date: string }) => dayjs(a.date).diff(dayjs(b.date))),
					deadlines: (deadlinesQuery.data || []).toSorted((a: { date: string }, b: { date: string }) => dayjs(a.date).diff(dayjs(b.date))),
				}}
			/>
		</PDFViewer>
	);
};
