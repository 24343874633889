import { GridSortItem } from "@mui/x-data-grid";
import { Document, Page, PDFViewer, StyleSheet, View, Text } from "@react-pdf/renderer";
import dayjs from "dayjs";

const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		fontSize: "10px",
		paddingTop: "45px",
		paddingBottom: "45px",
	},
	titleContainer: {
		flexDirection: "row",
	},
	title: {
		fontSize: "14px",
		padding: "2px",
		width: "50%",
	},
	date: {
		padding: "2px",
		width: "50%",
		textAlign: "right",
		verticalAlign: "sub",
	},
	table: {
		marginTop: "8px",
	},
	headerRow: {
		flexDirection: "row",
		backgroundColor: "#666",
		color: "#fff",
	},
	row: {
		flexDirection: "row",
		borderBottom: "1px dotted black",
	},
	clientNameRow: {
		width: "22%",
		padding: "4px",
	},
	attorneyRow: {
		width: "15%",
		padding: "4px",
	},
	statusRow: {
		width: "8%",
		padding: "4px",
	},
	caseTypeRow: {
		width: "20%",
		padding: "4px",
	},
	staffRow: {
		width: "15%",
		padding: "4px",
	},
	deadlineRow: {
		width: "20%",
		padding: "4px",
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1,
	},
});

const PDFDocument = ({ data, deadlines }: { data: object[]; deadlines: { [id: string]: string } }) => {
	return (
		<Document>
			<Page size="LETTER" style={styles.page}>
				<View style={styles.section}>
					<View style={styles.titleContainer}>
						<Text style={styles.title}>Case List</Text>
						<Text style={styles.date}>AS OF {dayjs(Date.now()).format("MM/DD/YYYY hh:mm A")}</Text>
					</View>
					<View style={styles.table}>
						<View style={styles.headerRow}>
							<Text style={styles.clientNameRow}>Client Name</Text>
							<Text style={styles.attorneyRow}>Attorney</Text>
							<Text style={styles.statusRow}>Status</Text>
							<Text style={styles.caseTypeRow}>Case Type</Text>
							<Text style={styles.staffRow}>Staff Assigned</Text>
							<Text style={styles.deadlineRow}>Upcoming Deadline</Text>
						</View>
						{data.map((datum: any) => (
							<View style={styles.row} wrap={false}>
								<Text style={styles.clientNameRow}>{datum["Client Name$"] || datum["Client Name$_0"]}</Text>
								<Text style={styles.attorneyRow}>{datum["attorney"]}</Text>
								<Text style={styles.statusRow}>{datum["status"]}</Text>
								<Text style={styles.caseTypeRow}>{datum["caseType"]}</Text>
								<Text style={styles.staffRow}>{datum["staff"]}</Text>
								<Text style={styles.deadlineRow}>
									{deadlines[datum["_id"]] === undefined ? (
										<Text style={{ color: "#aaa" }}>No Upcoming Deadlines</Text>
									) : (
										dayjs(deadlines[datum["_id"]]).format("MM/DD/YYYY hh:mm A")
									)}
								</Text>
							</View>
						))}
					</View>
				</View>
			</Page>
		</Document>
	);
};

export const GalleryPDF = ({ sortModel, data, deadlines }: { sortModel: GridSortItem[]; data: object[]; deadlines: { [id: string]: string } }) => {
	let sortedData = [...data];
	for (let e of sortModel) {
		switch (e.field) {
			case "clientName":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) =>
							(a["Client Name$"] || a["Client Name$_0"]) < (b["Client Name$"] || b["Client Name$_0"])
								? -1
								: (a["Client Name$"] || a["Client Name$_0"]) === (b["Client Name$"] || b["Client Name$_0"])
								? 0
								: 1
						);
						break;
					case "desc":
						sortedData.sort((a: any, b: any) =>
							(a["Client Name$"] || a["Client Name$_0"]) < (b["Client Name$"] || b["Client Name$_0"])
								? 1
								: (a["Client Name$"] || a["Client Name$_0"]) === (b["Client Name$"] || b["Client Name$_0"])
								? 0
								: -1
						);
						break;
				}
				break;
			case "attorney":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => (a["attorney"] < b["attorney"] ? -1 : a["attorney"] === b["attorney"] ? 0 : 1));
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => (a["attorney"] < b["attorney"] ? 1 : a["attorney"] === b["attorney"] ? 0 : -1));
						break;
				}
				break;
			case "caseStatus":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => (a["status"] < b["status"] ? -1 : a["status"] === b["status"] ? 0 : 1));
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => (a["status"] < b["status"] ? 1 : a["status"] === b["status"] ? 0 : -1));
						break;
				}
				break;
			case "caseType":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => (a["caseType"] < b["caseType"] ? -1 : a["caseType"] === b["caseType"] ? 0 : 1));
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => (a["caseType"] < b["caseType"] ? 1 : a["caseType"] === b["caseType"] ? 0 : -1));
						break;
				}
				break;
			case "staff":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => (a["staff"] < b["staff"] ? -1 : a["staff"] === b["staff"] ? 0 : 1));
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => (a["staff"] < b["staff"] ? 1 : a["staff"] === b["staff"] ? 0 : -1));
						break;
				}
				break;
			case "deadline":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => {
							if (deadlines[a["_id"]] === undefined && deadlines[b["_id"]] === undefined) {
								return 0;
							}
							if (deadlines[a["_id"]] === undefined) {
								return -1;
							}
							if (deadlines[b["_id"]] === undefined) {
								return 1;
							}
							return dayjs(deadlines[b["_id"]]).diff(dayjs(deadlines[a["_id"]]));
						});
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => {
							if (deadlines[a["_id"]] === undefined && deadlines[b["_id"]] === undefined) {
								return 0;
							}
							if (deadlines[a["_id"]] === undefined) {
								return 1;
							}
							if (deadlines[b["_id"]] === undefined) {
								return -1;
							}
							return -dayjs(deadlines[b["_id"]]).diff(dayjs(deadlines[a["_id"]]));
						});
						break;
				}
				break;
		}
	}
	return (
		<PDFViewer
			style={{
				width: "100%",
				height: "100%",
			}}
		>
			<PDFDocument data={sortedData} deadlines={deadlines} />
		</PDFViewer>
	);
};
