import { Box, Button, FormControl, FormHelperText, MenuItem, Select, TextField } from "@mui/material";
import { DataGrid, GridSortItem } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { SettingsObject } from "../utils";

function getRowData(data: { [id: string]: any }[], deadlines: { [id: string]: string }) {
	return data.map((datum: { [id: string]: any }) => ({
		id: datum["_id"],
		edit: datum["_id"],
		clientName: datum["Client Name$"] || datum["Client Name$_0"],
		attorney: datum["attorney"],
		caseStatus: datum["status"] === "Active",
		caseType: datum["caseType"],
		staff: datum["staff"],
		deadline: deadlines[datum["_id"]],
	}));
}

interface SearchFormProps {
	settings: SettingsObject;
	data: object[];
	deadlines: { [id: string]: string };
	isLoading: boolean;
	setDetailsModal: (id: string) => void;
	attorney: string;
	setAttorney: (arg0: string) => void;
	caseType: string;
	setCaseType: (arg0: string) => void;
	assignedStaff: string;
	setAssignedStaff: (arg0: string) => void;
	accountType: string;
	setAccountType: (arg0: string) => void;
	drmOrOther: string;
	setDrmOrOther: (arg0: string) => void;
	status: string;
	setStatus: (arg0: string) => void;
	nameSearch: string;
	setNameSearch: (arg0: string) => void;
	opposingSearch: string;
	setOpposingSearch: (arg0: string) => void;
	setSortModel: (arg0: GridSortItem[]) => void;
}

export function SearchForm({
	settings: { caseTypes, attorneyList, accountTypes, drmOtherTypes, staffList, statusTypes },
	data,
	deadlines,
	isLoading,
	setDetailsModal,
	attorney,
	setAttorney,
	caseType,
	setCaseType,
	assignedStaff,
	setAssignedStaff,
	accountType,
	setAccountType,
	drmOrOther,
	setDrmOrOther,
	status,
	setStatus,
	nameSearch,
	setNameSearch,
	opposingSearch,
	setOpposingSearch,
	setSortModel,
}: SearchFormProps) {
	return (
		<>
			<div>
				<Button
					variant="outlined"
					sx={{ m: 1 }}
					onClick={() => {
						setNameSearch("");
						setOpposingSearch("");
						setAttorney("All");
						setCaseType("All");
						setAssignedStaff("All");
						setAccountType("All");
						setDrmOrOther("All");
						setStatus("Active");
					}}
				>
					Clear Filters
				</Button>
			</div>
			<div>
				<FormControl sx={{ m: 1, minWidth: 200 }}>
					<Select value={attorney} onChange={(event) => setAttorney(event.target.value)}>
						<MenuItem
							value="All"
							style={{
								fontStyle: "italic",
							}}
						>
							All
						</MenuItem>
						{attorneyList.map((v) => (
							<MenuItem key={v} value={v}>
								{v}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>Attorney</FormHelperText>
				</FormControl>
				<FormControl sx={{ m: 1, minWidth: 200 }}>
					<Select value={caseType} onChange={(event) => setCaseType(event.target.value)}>
						<MenuItem
							value="All"
							style={{
								fontStyle: "italic",
							}}
						>
							All
						</MenuItem>
						{caseTypes.map((v) => (
							<MenuItem key={v} value={v}>
								{v}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>Case Type</FormHelperText>
				</FormControl>
				<FormControl sx={{ m: 1, minWidth: 200 }}>
					<Select value={assignedStaff} onChange={(event) => setAssignedStaff(event.target.value)}>
						<MenuItem
							value="All"
							style={{
								fontStyle: "italic",
							}}
						>
							All
						</MenuItem>
						{staffList.map((v) => (
							<MenuItem key={v} value={v}>
								{v}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>Assigned Staff</FormHelperText>
				</FormControl>
				<FormControl sx={{ m: 1, minWidth: 200 }}>
					<Select value={accountType} onChange={(event) => setAccountType(event.target.value)}>
						<MenuItem
							value="All"
							style={{
								fontStyle: "italic",
							}}
						>
							All
						</MenuItem>
						{accountTypes.map((v) => (
							<MenuItem key={v} value={v}>
								{v}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>Account Type</FormHelperText>
				</FormControl>
				<FormControl sx={{ m: 1, minWidth: 200 }}>
					<Select value={drmOrOther} onChange={(event) => setDrmOrOther(event.target.value)}>
						<MenuItem
							value="All"
							style={{
								fontStyle: "italic",
							}}
						>
							All
						</MenuItem>
						{drmOtherTypes.map((v) => (
							<MenuItem key={v} value={v}>
								{v}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>DRM or Other</FormHelperText>
				</FormControl>
				<FormControl sx={{ m: 1, minWidth: 200 }}>
					<Select value={status} onChange={(event) => setStatus(event.target.value)}>
						<MenuItem
							value="All"
							style={{
								fontStyle: "italic",
							}}
						>
							All
						</MenuItem>
						{statusTypes.map((v) => (
							<MenuItem key={v} value={v}>
								{v}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>Active</FormHelperText>
				</FormControl>
			</div>
			<div>
				<FormControl sx={{ m: 1, minWidth: 480 }}>
					<TextField value={nameSearch} onChange={(event) => setNameSearch(event.target.value)} />
					<FormHelperText>Search Client Name</FormHelperText>
				</FormControl>
				<FormControl sx={{ m: 1, minWidth: 480 }}>
					<TextField value={opposingSearch} onChange={(event) => setOpposingSearch(event.target.value)} />
					<FormHelperText>Opposing Counsel</FormHelperText>
				</FormControl>
			</div>
			<div id="datagrid-start" />
			<Box sx={{ p: 1, height: `calc(100vh - ${document.getElementById("datagrid-start")?.getBoundingClientRect().top}px - 24px)`, minHeight: "300px" }}>
				<DataGrid
					sx={{
						"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
							outline: "none !important",
						},
					}}
					initialState={{
						sorting: {
							sortModel: [{ field: "clientName", sort: "asc" }],
						},
					}}
					pageSizeOptions={[10, 25, 100]}
					disableColumnMenu
					loading={isLoading}
					onSortModelChange={(model) => setSortModel(model)}
					onRowClick={(params) => setDetailsModal(params.row.id)}
					columns={[
						{
							field: "clientName",
							headerName: "Client Name",
							flex: 1,
							filterable: false,
							hideable: false,
						},
						{
							field: "attorney",
							headerName: "Attorney",
							flex: 1,
							filterable: false,
							hideable: false,
						},
						{
							field: "caseStatus",
							headerName: "Active",
							flex: 0.5,
							type: "boolean",
							align: "left",
							headerAlign: "left",
							filterable: false,
							hideable: false,
						},
						{
							field: "caseType",
							headerName: "Case Type",
							flex: 1,
							filterable: false,
							hideable: false,
						},
						{
							field: "staff",
							headerName: "Staff Assigned",
							flex: 1,
							filterable: false,
							hideable: false,
						},
						{
							field: "deadline",
							flex: 1,
							headerName: "Upcoming Deadline",
							filterable: false,
							hideable: false,
							sortComparator: (a, b) => {
								if (a === undefined && b === undefined) {
									return 0;
								}
								if (a === undefined) {
									return -1;
								}
								if (b === undefined) {
									return 1;
								}
								return dayjs(b).diff(dayjs(a));
							},
							renderCell: (params) =>
								params.value === undefined ? (
									<span style={{ color: "grey" }}>No Upcoming Deadlines</span>
								) : (
									<span style={{ color: dayjs(params.value).diff(dayjs(), "days") < 7 ? "red" : "inherit" }}>
										{dayjs(params.value).format("MM/DD/YYYY hh:mm A")}
									</span>
								),
						},
					]}
					rows={getRowData(data, deadlines)}
				/>
			</Box>
		</>
	);
}
