import { ArrowDownward, ArrowUpward, Delete, Restore, Save } from "@mui/icons-material";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	IconButton,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useQuery } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Detail, QueryStatus, SessionState, SessionStatus, getDetails, postAction } from "../utils";

export enum DetailsType {
	Note,
	Task,
	Deadline,
}

interface DetailsRowProps {
	isLast: boolean;
	type: DetailsType;
	detail: Detail;
	saveKeyPressed: boolean;
	deleteDetail: (id: string) => void;
	updateDetail: (id: string, date: Dayjs, assignee: string, description: string) => void;
	declareEdited: (arg0: string, arg1: boolean) => void;
	setIsComplete: (arg0: string, value: boolean) => void;
}

function DetailsRow({ isLast, type, detail, deleteDetail, updateDetail, declareEdited, setIsComplete, saveKeyPressed }: DetailsRowProps) {
	const [newDate, setNewDate] = useState(dayjs(detail.date));
	const [newAssignee, setNewAssignee] = useState(detail.assignee);
	const [newDescription, setNewDescription] = useState(detail.description);

	const isDateEdited = newDate.unix() !== dayjs(detail.date).unix();
	const isAssigneeEdited = newAssignee !== detail.assignee;
	const isDescriptionEdited = newDescription !== detail.description;
	const isEdited = isDateEdited || isAssigneeEdited || isDescriptionEdited;

	const isSevenDaysOut = dayjs(detail.date).diff(dayjs(), "days") < 7;
	const isPassed = dayjs(detail.date).diff(dayjs(), "days") < -1;
	let background = "none";
	let hoverBackground = "#212121";
	if ([DetailsType.Task, DetailsType.Deadline].includes(type)) {
		if (isSevenDaysOut && !isPassed) {
			background = "#221111";
			hoverBackground = "#332222";
		}
	}

	useEffect(() => declareEdited(`details-${type}`, isEdited), [isEdited]);

	useEffect(() => {
		if (saveKeyPressed === true) {
			if (!isEdited) {
				return;
			}
			updateDetail(detail._id, newDate, newAssignee, newDescription);
		}
	}, [saveKeyPressed]);

	return (
		<Box
			sx={{
				display: "flex",
				background: background,
				borderBottom: !isLast ? (theme) => `1px solid ${theme.palette.grey[800]}` : "none",
				":hover": { background: hoverBackground },
			}}
		>
			{[DetailsType.Task, DetailsType.Deadline].includes(type) && (
				<Tooltip title="Mark as complete">
					<Checkbox size="large" checked={detail?.isComplete} onChange={(_, value) => setIsComplete(detail?._id, value)} />
				</Tooltip>
			)}
			<DateTimePicker
				label={isDateEdited ? dayjs(detail.date).format("MM/DD/YYYY") : undefined}
				// disabled={[DetailsType.Note].includes(type as DetailsType)}
				sx={{ m: 1, width: "220px" }}
				slotProps={{ textField: { size: "small", focused: isDateEdited, color: isDateEdited ? "secondary" : "primary" } }}
				value={newDate}
				onChange={(value) => {
					if (value !== null) {
						setNewDate(value);
					}
				}}
			/>
			<TextField
				sx={{ m: 1, width: "120px" }}
				size="small"
				focused={isAssigneeEdited}
				color={isAssigneeEdited ? "secondary" : "primary"}
				label={isAssigneeEdited ? detail.assignee : undefined}
				value={newAssignee}
				onChange={(e) => setNewAssignee(e.target.value)}
			/>
			<TextField
				size="small"
				sx={{ flex: 1, m: 1 }}
				focused={isDescriptionEdited}
				color={isDescriptionEdited ? "secondary" : "primary"}
				label={isDescriptionEdited ? detail.description : undefined}
				value={newDescription}
				multiline
				onChange={(e) => setNewDescription(e.target.value)}
			/>
			<Box>
				<Tooltip title="Save Changes">
					<IconButton sx={{ m: 1 }} disabled={!isEdited} onClick={() => updateDetail(detail._id, newDate, newAssignee, newDescription)}>
						<Save />
					</IconButton>
				</Tooltip>
				{isEdited ? (
					<Tooltip title="Undo Changes">
						<IconButton
							sx={{ m: 1 }}
							onClick={() => {
								setNewDate(dayjs(detail.date));
								setNewAssignee(detail.assignee);
								setNewDescription(detail.description);
							}}
						>
							<Restore />
						</IconButton>
					</Tooltip>
				) : (
					<Tooltip title="Delete">
						<IconButton sx={{ m: 1 }} onClick={() => deleteDetail(detail._id)}>
							<Delete />
						</IconButton>
					</Tooltip>
				)}
			</Box>
		</Box>
	);
}

interface DetailsGridProps {
	type: DetailsType;
	id: string;
	sessionState: SessionState;
	saveKeyPressed: boolean;
	setSessionState: (arg0: SessionState) => void;
	setPostStatus: (arg0: QueryStatus) => void;
	declareEdited: (arg0: string, arg1: boolean) => void;
	defaultDirection: "up" | "down";
}

export function DetailsGrid({ type, id, sessionState, setSessionState, setPostStatus, declareEdited, defaultDirection, saveKeyPressed }: DetailsGridProps) {
	const [notesTerm, setNotesTerm] = useState("");
	const [addDetailDialog, setAddDetailDialog] = useState<DetailsType | null>(null);
	const [deleteDialog, setDeleteDialog] = useState<string | null>(null);
	const [direction, setDirection] = useState(defaultDirection);

	const [date, setDate] = useState<null | Dayjs>(null);
	const [assignee, setAssignee] = useState("");
	const [description, setDescription] = useState("");

	const detailsQuery = useQuery({
		queryKey: ["details", type, id],
		queryFn: () =>
			getDetails(
				{
					archived: false,
					caseId: id,
					type: { [DetailsType.Note]: "note", [DetailsType.Task]: "task", [DetailsType.Deadline]: "deadline" }[type],
				},
				sessionState,
				setSessionState
			),
		enabled: false,
	});

	useEffect(() => {
		if (sessionState.status === SessionStatus.LOGGED_IN) {
			detailsQuery.refetch();
		}
	}, [sessionState]);

	const rawDetailList: { _id: string; date: string; assignee: string; description: string }[] = (detailsQuery.data || [])
		.filter(({ description }: { description: string }) => description.toLowerCase().includes(notesTerm.toLowerCase()))
		.toSorted((a: { date: string }, b: { date: string }) =>
			direction === "down" ? -dayjs(a.date).diff(dayjs(b.date)) : dayjs(a.date).diff(dayjs(b.date))
		);

	const currentDetails = rawDetailList.filter(({ date }) => dayjs(date).diff(dayjs(), "days") >= -1);
	const passedDetails = rawDetailList.filter(({ date }) => dayjs(date).diff(dayjs(), "days") < -1);

	const createDetailRow = (detail: { _id: string; date: string; assignee: string; description: string; isComplete?: boolean }, isLast: boolean) => (
		<DetailsRow
			saveKeyPressed={saveKeyPressed}
			declareEdited={declareEdited}
			isLast={isLast}
			key={detail._id}
			type={type}
			detail={detail}
			deleteDetail={(deleteId) => setDeleteDialog(deleteId)}
			updateDetail={(detailId, newDate, newAssignee, newDescription) => {
				setPostStatus(QueryStatus.LOADING);
				postAction(
					{
						action: "update-detail",
						id: detailId,
						data: {
							date: newDate,
							assignee: newAssignee,
							description: newDescription,
						},
					},
					sessionState,
					setSessionState,
					() => {
						setPostStatus(QueryStatus.SUCCESS);
						detailsQuery.refetch();
						setAddDetailDialog(null);
					},
					(e) => {
						console.error(e);
						setPostStatus(QueryStatus.FAILURE);
					}
				);
			}}
			setIsComplete={(detailId, value) => {
				setPostStatus(QueryStatus.LOADING);
				postAction(
					{
						action: "update-detail-complete",
						id: detailId,
						isComplete: value,
					},
					sessionState,
					setSessionState,
					() => {
						setPostStatus(QueryStatus.SUCCESS);
						detailsQuery.refetch();
						setAddDetailDialog(null);
					},
					(e) => {
						console.error(e);
						setPostStatus(QueryStatus.FAILURE);
					}
				);
			}}
		/>
	);

	return (
		<>
			<Box sx={{ display: "flex", marginBottom: "5px" }}>
				<ToggleButtonGroup sx={{ marginRight: "5px" }} size="small" value={direction} onChange={(_, value) => setDirection(value)} exclusive>
					<Tooltip title="Sort Descending">
						<ToggleButton value="down">
							<ArrowDownward />
						</ToggleButton>
					</Tooltip>
					<Tooltip title="Sort Ascending">
						<ToggleButton value="up">
							<ArrowUpward />
						</ToggleButton>
					</Tooltip>
				</ToggleButtonGroup>
				<TextField
					size="small"
					sx={{ flexGrow: 1, marginRight: "5px" }}
					label={`Search ${{ [DetailsType.Note]: "notes", [DetailsType.Task]: "tasks", [DetailsType.Deadline]: "deadlines" }[type]}...`}
					value={notesTerm}
					onChange={(e) => setNotesTerm(e.target.value)}
				></TextField>
				<Button
					variant="outlined"
					onClick={() => {
						setAddDetailDialog(type);
						setDescription("");
						setAssignee("");
						setDate(dayjs());
					}}
				>
					Add {{ [DetailsType.Note]: "Note", [DetailsType.Task]: "Task", [DetailsType.Deadline]: "Deadline" }[type]}
				</Button>
			</Box>
			<Box sx={{ borderRadius: "4px", border: (theme) => `1px solid ${theme.palette.grey[800]}`, height: "calc(100% - 80px)" }}>
				<Box
					sx={{
						height: "100%",
						overflow: "auto",
					}}
				>
					{currentDetails.length + passedDetails.length === 0 ? (
						<Typography sx={{ color: (theme) => theme.palette.text.secondary, marginTop: "40px", display: "flex", justifyContent: "center" }}>
							No {{ [DetailsType.Note]: "notes", [DetailsType.Task]: "tasks", [DetailsType.Deadline]: "deadlines" }[type]}
						</Typography>
					) : (
						<>
							{type === DetailsType.Deadline ? (
								<>
									{currentDetails.map((detail, i) => createDetailRow(detail, i === currentDetails.length - 1))}
									{passedDetails.length > 0 && (
										<>
											<Divider sx={{ marginTop: "10px", marginBottom: "10px", color: (theme) => theme.palette.text.disabled }}>
												Passed Deadlines
											</Divider>
											{passedDetails.map((detail, i) => createDetailRow(detail, i === passedDetails.length - 1))}
										</>
									)}
								</>
							) : (
								<>{rawDetailList.map((detail, i) => createDetailRow(detail, i === rawDetailList.length - 1))}</>
							)}
						</>
					)}
				</Box>
			</Box>
			<Dialog open={addDetailDialog !== null} onClose={() => setAddDetailDialog(null)}>
				<DialogTitle>Add Detail</DialogTitle>
				<DialogContent>
					<Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
						<FormControl sx={{ m: 1, width: 240 }}>
							<DateTimePicker
								// disabled={[DetailsType.Note].includes(addDetailDialog as DetailsType)}
								label="Date"
								value={date}
								onChange={setDate}
							/>
						</FormControl>
						<FormControl sx={{ m: 1, width: 240 }}>
							<TextField label="Assignee" value={assignee} onChange={(e) => setAssignee(e.target.value)}></TextField>
						</FormControl>
						<FormControl sx={{ m: 1, width: 540 }}>
							<TextField
								label="Description"
								variant="filled"
								multiline
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							></TextField>
						</FormControl>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setAddDetailDialog(null)}>Cancel</Button>
					<Button
						variant="contained"
						onClick={() => {
							setPostStatus(QueryStatus.LOADING);
							postAction(
								{
									action: "add-detail",
									id,
									data: {
										date,
										assignee,
										description,
										caseId: id,
										type: { [DetailsType.Note]: "note", [DetailsType.Task]: "task", [DetailsType.Deadline]: "deadline" }[
											addDetailDialog as DetailsType
										],
									},
								},
								sessionState,
								setSessionState,
								() => {
									setPostStatus(QueryStatus.SUCCESS);
									detailsQuery.refetch();
									setAddDetailDialog(null);
								},
								(e) => {
									console.error(e);
									setPostStatus(QueryStatus.FAILURE);
								}
							);
						}}
					>
						Add
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={deleteDialog !== null} onClose={() => setDeleteDialog(null)}>
				<DialogTitle>{"Are you sure you want to archive this detail?"}</DialogTitle>
				<DialogActions>
					<Button onClick={() => setDeleteDialog(null)}>Cancel</Button>
					<Button
						autoFocus
						variant="contained"
						onClick={() => {
							setPostStatus(QueryStatus.LOADING);
							postAction(
								{
									action: "delete-detail",
									id: deleteDialog,
								},
								sessionState,
								setSessionState,
								() => {
									setPostStatus(QueryStatus.SUCCESS);
									detailsQuery.refetch();
									setDeleteDialog(null);
								},
								(e) => {
									console.error(e);
									setPostStatus(QueryStatus.FAILURE);
								}
							);
						}}
					>
						Archive
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
